<template>
    <div>
        <div class="header">
            <van-nav-bar :title="$t('服务协议')" left-arrow @click-left="$router.go(-1)" />
        </div>
        <div class="maincontent">
            <template v-if="lang == 'zh'">
                <pre>
    如果您通过我们的网站申请保证金融资服务,除AFOUR 服 务条款外,本保证金融资协议(“MFA”)也将适用于您。如 果本 MFA与AFOUR 服务条款之间存在任何不一致,则以 本MFA为准。

我们提供此部分是为了向您提供有关以保证金购买资产的一 些基本事实,并提醒您注意保证金交易所涉及的风险。通过 保证金账户进行交易风险很高,可能会导致您存入保证金账 户的所有资产损失。此外,您必须充分了解保证金交易资产 所涉及的风险。

1. 解释和定义

本 MFA 中使用的大写术语若未在此处定义,则具有用户协 议中赋予它们的含义。

“资产”

指任何合约,例如衍生品或期货合约,或任何数字资产。

“利润”

指您存入保证金账户以承担保证金交易风险的资产金额。

“追加保证金”

指我们 AFOUR 向您发出的增加保证金的指令。

「保证金账户」

指向您提供保证金便利的账户。

“保证金交易”

指参与通过向您提供的保证金便利全部或部分购买资产的任 何交易。

“保证金要求”

指本网站规定的我们向您授予保证金的要求、限制和利益, 我们可能会不时修改,恕不另行通知。

“清算”

指因保证金不符合保证金要求而强制出售属于用户的资产。

2. 您的保证金账户中的资产将作为向您出借资产的抵押品。 如果资产贬值,抵押品也会贬值。因此,我们可以采取行 动,例如出售您在我们这里持有的保证金账户中的抵押品或 发出追加保证金通知,以维持保证金账户所需的资产价值。

3. 您应该了解,根据本MFA,我们通常不会发出追加保证 金通知,也不会存入您的账户或增加您的保证金以满足保证 金要求。相反,我们通常会在不事先通知您的情况下清算您 的保证金账户中的头寸,以满足保证金要求,并且您没有机 会选择要清算的头寸或清算的时间或顺序。

4. 如果我们因任何原因不平仓但发出追加保证金通知,您应 立即将资产存入您的保证金账户以满足我们的追加保证金通 知。

5.您损失的资产可能多于您存入保证金账户的资产。通过保 证金购买的资产价值下降可能需要您提供额外的资产以避免 清算。

6.本网站上的保证金要求仅供参考,并不反映实际的保证金 要求,实际的保证金要求可能会根据市场状况而迅速变化。

7. 我们可自行决定随时修改和提高保证金要求,恕不书面通 知您。这些更改通常会立即生效,并将影响您现有的利润。 如果您对保证金的使用不符合修订或提高的保证金要求,您 可能会立即被清算,恕不另行通知。

8. 保证金始终遵守我们制定的保证金要求,您同意并承诺将 您的保证金维持在保证金要求范围内。

9. 我们的保证金要求或风险控制可能包括头寸规模限制或其 他限制。如果达到或超过这些限制,您可能无法下新订单, 并且您授权我们在不通知的情况下代表您清算现有头寸和/或 进行风险降低交易,以使您的保证金账户恢复符合相关的保 证金要求。

10. 为了确定您是否遵守我们的保证金要求,我们将自行决 定确定您保证金账户中的头寸和资产的价值。我们的计算可 能与其他市场公布的估值和价格有所不同,您同意无条件接 受我们的估值。

11. 如果保证金低于保证金要求或我们随时将保证金要求修改 为更高,我们可以出售您保证金账户中的资产以弥补保证金 不足。清算不足以弥补缺口的,您将承担弥补缺口的法律责 任。

12. 如果您的保证金账户资产不足以满足保证金要求,我们 可能会拒绝任何订单,并且在确定您的保证金账户的保证金 状态时可能会延迟处理任何订单。

13. 在追加保证金通知或清算生效之前,我们没有义务与您 联系。因此,您应该明白,您需要随时了解之前的保证金要 求,以及保证金要求在任何时候对您不利的变化时保证金将 如何影响您。您还应该确保对符合您风险承受能力的头寸进 行适当的风险管理。

14. 清算通常会自动发生,但尽管有上述规定,如果您的保 证金账户不符合保证金要求,我们没有义务采取任何行动。

15. 如果您不遵守保证金要求,您不得依赖我们来平仓或清 算您保证金账户中的头寸。特别是,您不得依赖自动清算权 和系统作为止损单。您不得假设我们会清算头寸以防止您的 损失超过您存入的金额。同样,我们可以自行决定并考虑我 们的利益,延迟或决定不清算您保证金账户中存在保证金赤 字的头寸,并且对于您因延迟或暂缓清算而遭受的任何损失 不承担任何责任。

16.您无权选择清算哪些资产。我们拥有自行决定权和权利 决定清算哪些头寸以保护我们的利益。在保证金不足的情况 下,我们可能允许您请求对您的保证金账户中的资产进行清 算,但此类请求对我们没有约束力,我们保留确定要清算的 资产的唯一酌情权,包括清算的顺序和方式清算。我们可以 自行决定通过任何方法清算您的头寸,并且我们或我们的关 联公司可以承担此类清算的交易对手头寸。

17.卖空的特殊风险。与卖空资产相关的其他风险可能会让 您蒙受重大损失。如果您的保证金账户中没有维持足够的保 证金,我们可能会通过回购资产来平仓您的头寸。如果资产 价格高于您卖空的价格,您可能会遭受重大损失。卖空带来 无限的市场风险,并可能导致巨大损失,因为您可能必须以 高于出售价格的价格购买资产才能弥补空头头寸。资产的价 格可以涨到多高是没有限制的。当您卖空资产时,我们必须 从我们自己的库存、外包流动性提供商或我们市场中其他用 户提供的流动性中向您借出资产。借用资产可能会被召回, 恕不另行通知。资产贷方保留随时收回其资产的权利。如果 我们在召回通知后无法借入资产或重新借入资产,我们可能 会在不通知您的情况下代表您买入资产,以弥补空头头寸。 您应对买入时产生的任何损失或费用负责,包括任何相关的 交易佣金或费用。

18.您同意我们有权自行决定随时以任何方式清算和/或抵消 您任何保证金账户中的全部或部分头寸或资产,而无需事先 通知。在以下情况下随时通知您:

a)您的保证金账户出现赤字;

b)您的资产不足以满足保证金要求;

c)我们自行判断您持有的任何头寸可能或将会导致未来违反 我们的保证金要求;

d)当您执行您没有足够资产的订单时;

e)当我们自行决定有必要进行清算以保护您时;

f)如果出现违约情况,即您违反本 MFA 或我们的用户协议 中的一项或多项条款;

g)本协议终止;

h)任何一方启动法律或调查程序。

(每个都是“清算触发器”)

19.您应对您的保证金账户中因此类清算而产生的或此类清 算后仍然存在的缺陷承担责任并立即向我们付款。我们对您 因此类清算而遭受的任何损失(或我们的疏忽)不承担任何 责任,即使您以最差的头寸重新建立清算头寸。对于与我们 进行的此类清算相关的所有行动、疏忽、成本、费用(包括 但不限于律师费)或责任,您应赔偿我们并使其免受损害。

20. 如果发生清算触发,您同意我们拥有唯一的权利和酌处 权,冻结您的全部或部分保证金账户或资产和/或行使您的保 证金账户上的头寸,而无需事先通知您。

21. 这些条款和保证金要求旨在保护市场的完整性,而不是 为了保护您。尽管如此,您理解并同意,通过使用我们的保 证金设施,您将受到本 MFA 和保证金要求的约束。我们未 能应用或执行保证金要求中的任何条款并不赋予您对我们提 起诉讼的任何权利,并且本协议中的任何内容均不构成我们 将应用或执行保证金要求的保证或承诺。
                </pre>
            </template>
            <template v-else>
                <pre>
    If you apply for margin financing services through our website, this Margin Financing Agreement (“MFA”) will apply to you in addition to the AFOUR Terms of Service. If there is any inconsistency between this MFA and the AFOUR Terms of Service, this MFA will prevail.

We provide this section to provide you with some basic facts about purchasing assets on margin and to remind you of the risks involved in margin trading. Trading through a margin account is highly risky and may result in the loss of all assets you deposit into your margin account. In addition, you must fully understand the risks involved in trading assets on margin.

1. Interpretation and Definitions

Capitalized terms used in this MFA have the meanings given to them in the User Agreement if not defined herein.

“Assets”

Means any contract, such as a derivative or futures contract, or any digital asset.

“Profit”

Means the amount of assets you deposit into your margin account to bear the risk of margin trading.

“Margin Call”

Means an instruction issued by us, AFOUR, to you to increase your margin.

"Margin Account"

means the account for which you are provided with margin facilities.

"Margin Trading"

means participating in any transaction that involves the purchase of assets in whole or in part through the margin facilities provided to you.

"Margin Requirements"

means the requirements, restrictions and benefits for us to grant you margin as set out on this Website, which we may amend from time to time without notice.

"Liquidation"

means the forced sale of assets belonging to a user due to the margin not meeting the margin requirements.

2. The assets in your margin account will be used as collateral for the assets loaned to you. If the assets depreciate, the collateral will also depreciate. Therefore, we may take actions such as selling the collateral in your margin account held with us or issuing margin calls to maintain the required asset value in the margin account.

3. You should understand that under this MFA, we will not generally issue margin calls, nor will we deposit into your account or increase your margin to meet margin requirements. Instead, we will typically liquidate positions in your margin account to meet margin requirements without prior notice to you, and you will not have the opportunity to choose which positions to liquidate or the time or order of liquidation.

4. If we do not close a position for any reason but issue a margin call, you should immediately deposit assets into your margin account to meet our margin call.

5. You may lose more assets than you deposit into your margin account. A decline in the value of assets purchased on margin may require you to provide additional assets to avoid liquidation.

6. The margin requirements on this website are for reference only and do not reflect actual margin requirements, which may change rapidly depending on market conditions.

7. We may modify and increase margin requirements at any time at our discretion without notifying you in writing. These changes will generally take effect immediately and will affect your existing profits. If your use of margin does not meet the revised or increased margin requirements, you may be liquidated immediately without further notice.

8. Margin is always subject to the margin requirements set by us, and you agree and commit to maintain your margin within the margin requirements.

9. Our margin requirements or risk controls may include position size limits or other restrictions. If these limits are reached or exceeded, you may not be able to place new orders, and you authorize us to liquidate existing positions and/or enter into risk-reducing transactions on your behalf without notice to bring your margin account back into compliance with the relevant margin requirements.

10. In order to determine whether you comply with our margin requirements, we will determine the value of the positions and assets in your margin account at our discretion. Our calculations may differ from the valuations and prices published in other markets, and you agree to accept our valuations unconditionally.

11. If the margin is lower than the margin requirement or we modify the margin requirement to be higher at any time, we may sell the assets in your margin account to make up for the margin shortfall. If the liquidation is insufficient to make up the shortfall, you will be legally responsible for making up the shortfall.

12. If your Margin Account does not have sufficient equity to meet the Margin Requirements, we may reject any order and may delay processing any order while we determine the Margin Status of your Margin Account.

13. We are under no obligation to contact you before a Margin Call or liquidation takes effect. Therefore, you should understand that you need to be aware of previous Margin Requirements at all times and how Margin will affect you if Margin Requirements change against you at any time. You should also ensure that you exercise appropriate risk management for positions that are consistent with your risk tolerance.

14. Liquidations will usually occur automatically, but notwithstanding the foregoing, we are under no obligation to take any action if your Margin Account does not meet the Margin Requirements.

15. You must not rely on us to close or liquidate positions in your Margin Account if you do not comply with Margin Requirements. In particular, you must not rely on automatic liquidation rights and systems as stop-loss orders. You must not assume that we will liquidate positions to prevent you from losing more than the amount you deposited. Similarly, we may, at our sole discretion and taking into account our interests, delay or decide not to liquidate positions in your margin account that have a margin deficit and will not be liable for any losses you suffer as a result of such delay or delay.

16. You do not have the right to choose which assets to liquidate. We have the discretion and right to decide which positions to liquidate to protect our interests. In the event of insufficient margin, we may allow you to request the liquidation of assets in your margin account, but such request is not binding on us and we retain sole discretion to determine the assets to be liquidated, including the order and manner of liquidation. We may liquidate your positions by any method at our sole discretion and we or our affiliates may assume the counterparty position of such liquidation.

17. Special risks of short selling. Other risks associated with short selling assets may cause you to suffer significant losses. If you do not maintain sufficient margin in your margin account, we may close your position by repurchasing the asset. If the price of the asset is higher than the price at which you short sold it, you may suffer significant losses. Short selling carries unlimited market risk and can result in large losses, as you may have to buy the asset at a higher price than you sold it at to cover your short position. There is no limit to how high the price of an asset can go. When you short sell an asset, we must lend you the asset from our own inventory, from outsourced liquidity providers, or from liquidity provided by other users in our marketplace. Borrowed assets may be recalled without notice. Lenders of assets reserve the right to reclaim their assets at any time. If we are unable to borrow the asset or re-borrow it after a recall notice, we may buy the asset on your behalf to cover your short position without notifying you. You are responsible for any losses or expenses incurred on your purchase, including any associated transaction commissions or fees.

18. You agree that we have the right to liquidate and/or offset all or part of your positions or assets in any margin account at any time and in any manner at our sole discretion without prior notice. At any time upon notice to you:

a) your margin account is in deficit;

b) your assets are insufficient to meet margin requirements;

c) we determine in our sole discretion that any position you hold may or will result in a future violation of our margin requirements;

d) when you execute an order for which you do not have sufficient assets;

e) when we determine in our sole discretion that liquidation is necessary to protect you;

f) in the event of a default, i.e., a breach of one or more of the terms of this MFA or our User Agreement;

g) termination of this Agreement;

h) the initiation of legal or investigative proceedings by either party.

(each a “Liquidation Trigger”)

19. You shall be responsible for and shall promptly pay us for any defects in your margin account arising from such liquidation or which remain after such liquidation. We shall not be liable for any losses you suffer (or our negligence) as a result of such liquidation, even if you re-establish the liquidated position with the worst position. You shall indemnify and hold us harmless from all actions, omissions, costs, expenses (including but not limited to attorneys’ fees) or liabilities in connection with such liquidation by us.

20. If a liquidation trigger occurs, you agree that we have the sole right and discretion to freeze all or part of your margin account or assets and/or exercise positions on your margin account without prior notice to you.

21. These Terms and Margin Requirements are designed to protect the integrity of the market, not to protect you. Nevertheless, you understand and agree that by using our margin facilities, you will be bound by this MFA and the Margin Requirements. Our failure to apply or enforce any term in the Margin Requirements does not give you any right to bring an action against us, and nothing in this Agreement constitutes a guarantee or promise that we will apply or enforce the Margin Requirements.
                </pre>
            </template>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            lang: localStorage.getItem('lang') || 'en',
        };
    },
    created() {
    },
    methods: {

    }
};
</script>
<style lang="less" scoped>
.maincontent {
    padding-top: 50px;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    font-size: 0.93333rem;
    background: #f7f7f7;
    height: 100vh;

    pre {
        // 超出换行
        white-space: pre-wrap;
        word-wrap: break-word;
        // 字体如何向左对齐呢？
        text-align: justify;
        text-justify: inter-ideograph;
        // 字体颜色
        color: #333;
        padding: 15px;
    }

}
</style>
